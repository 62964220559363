import {getCompactDateAndLocationMargin, isCompactDateAndLocationVisible} from '../../selectors/single-settings'
import {AppProps} from '../app/interfaces'
import {renderIfVisible} from '../hoc/render-if-visible'
import {withMargin} from '../hoc/with-margin'
import {ShortDateLocationProps} from './interfaces'
import {SingleEventShortDateLocation as SingleEventShortDateLocationPresentation} from './short-date-location'

const getMargin = ({state}: AppProps) => getCompactDateAndLocationMargin(state)

export const SingleEventShortDateLocation = renderIfVisible<ShortDateLocationProps>(
  withMargin(SingleEventShortDateLocationPresentation, getMargin),
  isCompactDateAndLocationVisible,
)
