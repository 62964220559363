import type {ISettingsParam} from '@wix/tpa-settings'
import {useSettings as useYoshiSettings} from '@wix/tpa-settings/react'

export const useSettings = () => {
  const settings = useYoshiSettings()

  const get = (param: ISettingsParam) => {
    const value = settings.get(param)
    return value
  }

  const getWithFallback = (param: ISettingsParam, fallbackParam: ISettingsParam) => {
    const value = settings.get(param)
    const fallbackValue = settings.get(fallbackParam)

    return value ?? fallbackValue
  }

  return {
    ...settings,
    get,
    getWithFallback,
  }
}
