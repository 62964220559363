import {withTranslation} from '@wix/yoshi-flow-editor'
import {isMembersVisible as isListMembersVisible, isSingleEventWidget} from '../../selectors/settings'
import {isMembersVisible} from '../../selectors/single-settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {MembersOwnProps, MembersStateProps} from './interfaces'
import {Members as MembersPresentation} from './members'

const mapRuntime = ({actions, state}: AppProps, ownProps: MembersOwnProps): MembersStateProps => {
  const {event} = ownProps
  const showMembers = isSingleEventWidget(state.component)
    ? isMembersVisible(state, event)
    : isListMembersVisible(state, event)

  return {
    onClick: actions.openMembersModal,
    showMembers,
  }
}

export const Members = connect<MembersOwnProps, MembersStateProps>(mapRuntime)(withTranslation()(MembersPresentation))
