import {HORIZONTAL_IMAGE_POSITION, VERTICAL_IMAGE_POSITION, WIDGET_LAYOUT} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {getElementHeight} from '../../../../../commons/utils/dom'
import {getAlignmentClassName, getVerticalAlignmentClassName} from '../../selectors/classes'
import {Ribbon} from '../ribbon'
import {SingleEventShortDateLocation as ShortDateLocation} from '../single-short-date-location'
import {SingleEventSocialBar as SocialBar} from '../social-bar'
import {Countdown} from './countdown'
import {DateLocation} from './date-location'
import {Description} from './description'
import {Divider} from './divider'
import {Image} from './image'
import {SingleEventProps} from './interfaces'
import {Members} from './members'
import {RsvpButton} from './rsvp-button'
import s from './single-event.scss'
import {Title} from './title'

const MIN_WIDGET_HEIGHT_IN_ADI_EDITOR = 400
const RANDOM_PADDING_IN_ADI_EDITOR = 40
const contentId = 'ev-content'

export const SingleEvent = ({
  imageWidth,
  stillInIframe,
  horizontalLayout,
  verticalLayout,
  imagePosition,
  verticalImagePosition,
  height,
  rtl,
  imageVisible,
  event,
  alignment,
  verticalAlignment,
  showDate,
  showLocation,
  compactDividerVisible,
  compactDividerWidth,
  compactDividerHeight,
  buttonLayout,
  onImageLayout,
  mobile,
  t,
  showRibbon,
  layout,
}: SingleEventProps) => {
  const getHeightForIframeWidget = () => {
    const contentHeight = getElementHeight(`#${contentId}`)

    if (verticalLayout || (mobile && horizontalLayout)) {
      return contentHeight + contentHeight * (100 / imageWidth)
    }

    return Math.max(contentHeight + RANDOM_PADDING_IN_ADI_EDITOR, MIN_WIDGET_HEIGHT_IN_ADI_EDITOR)
  }

  const getContainerStyle = () => {
    const style: React.CSSProperties = {}

    if (mobile && layout !== WIDGET_LAYOUT.BACKGROUND) {
      style.minHeight = stillInIframe ? getHeightForIframeWidget() : buttonLayout ? undefined : height
    } else {
      style.height = stillInIframe ? getHeightForIframeWidget() : buttonLayout ? undefined : height
    }

    if (mobile) {
      style.flexDirection =
        verticalLayout && verticalImagePosition === VERTICAL_IMAGE_POSITION.BOTTOM ? 'column-reverse' : 'column'
    } else if (verticalLayout) {
      style.flexDirection = verticalImagePosition === VERTICAL_IMAGE_POSITION.BOTTOM ? 'column-reverse' : 'column'
    } else if (horizontalLayout) {
      if (imagePosition === HORIZONTAL_IMAGE_POSITION.LEFT) {
        style.flexDirection = rtl ? 'row-reverse' : 'row'
      } else {
        style.flexDirection = rtl ? 'row' : 'row-reverse'
      }
    }

    return style
  }

  const getContentContainerStyle = () => {
    const style: any = {}

    if (mobile) {
      style.width = '100%'
      style.minHeight = imageVisible && !onImageLayout ? `${100 - imageWidth}%` : '100%'

      if (imageVisible && !onImageLayout) {
        style.flexShrink = 0
      }
    } else {
      style.width = imageVisible && horizontalLayout ? `${100 - imageWidth}%` : '100%'
      style.height = imageVisible && verticalLayout ? `${100 - imageWidth}%` : '100%'
    }

    return style
  }

  const socialBarClassName = onImageLayout ? s.onImageSocial : s.social

  return (
    <div
      className={classNames(s.container, {
        [s.fullLayout]: !buttonLayout,
        [s.buttonLayout]: buttonLayout,
        [s.onImageLayout]: onImageLayout,
        [s.mobile]: mobile,
      })}
      style={getContainerStyle()}
      id="ev-frame"
    >
      <Image event={event} />
      <div
        style={getContentContainerStyle()}
        id="ev-content-container"
        data-hook="ev-content-container"
        className={classNames(
          s.contentContainer,
          s[getAlignmentClassName(alignment)],
          s[getVerticalAlignmentClassName(verticalAlignment)],
        )}
      >
        <div id={contentId} className={s.content}>
          {showRibbon ? <Ribbon event={event} className={s.ribbon} /> : null}
          <Countdown event={event} t={t} onImage={onImageLayout} />
          <Title event={event} onImage={onImageLayout} />
          <ShortDateLocation
            event={event}
            alignment={alignment}
            showDate={showDate}
            showLocation={showLocation}
            onImage={onImageLayout}
          />
          {compactDividerVisible && (
            <div className={s.compactDivider}>
              <Divider width={compactDividerWidth} height={compactDividerHeight} />
            </div>
          )}
          <Description event={event} onImage={onImageLayout} />
          <div>
            <DateLocation event={event} />
          </div>
          <Members event={event} onImage={onImageLayout} />
          <RsvpButton event={event} />
          <SocialBar hideTitle spacing={36} t={t} className={socialBarClassName} event={event} />
        </div>
      </div>
    </div>
  )
}
