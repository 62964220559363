import {getEventDateInformation} from '../../../../../../../commons/selectors/event'
import {getComponentConfig} from '../../../../selectors/settings'
import {
  getDateMargin,
  getHorizontalDividerHeight,
  getHorizontalDividerWidth,
  getLocationMargin,
  isDateVisible,
  isHorizontalDividersVisible,
  isLabelsVisible,
  isLocationVisible,
  isOnImageLayout,
} from '../../../../selectors/single-settings'
import {AppProps} from '../../../app/interfaces'
import {renderIfVisible} from '../../../hoc/render-if-visible'
import {withMargin} from '../../../hoc/with-margin'
import {connect} from '../../../runtime-context/connect'
import {PresentationOwnProps, PresentationStateProps} from './interfaces'
import {Presentation} from './presentation'

const mapLocationState = ({state}, ownProps: PresentationOwnProps): PresentationStateProps => {
  const componentConfig = getComponentConfig(state)

  return {
    text: ownProps.event.location.name,
    text2: ownProps.event.location.address,
    titleVisible: isLabelsVisible(state),
    dividerVisible: isHorizontalDividersVisible(state),
    dividerWidth: getHorizontalDividerWidth(componentConfig),
    dividerHeight: getHorizontalDividerHeight(componentConfig),
    onImage: isOnImageLayout(state),
    settingsKey: 'location',
  }
}

const mapDateState = ({state}, ownProps: PresentationOwnProps): PresentationStateProps => {
  const componentConfig = getComponentConfig(state)

  return {
    text: getEventDateInformation(state, ownProps.event.id).fullDate,
    titleVisible: isLabelsVisible(state),
    dividerVisible: isHorizontalDividersVisible(state),
    dividerWidth: getHorizontalDividerWidth(componentConfig),
    dividerHeight: getHorizontalDividerHeight(componentConfig),
    onImage: isOnImageLayout(state),
    settingsKey: 'time',
  }
}

const locationMargin = ({state}: AppProps) => getLocationMargin(state)

export const Location = renderIfVisible(
  withMargin(connect<PresentationOwnProps, PresentationStateProps>(mapLocationState)(Presentation), locationMargin),
  isLocationVisible,
)

const dateMargin = ({state}: AppProps) => getDateMargin(state)

export const Date = renderIfVisible(
  withMargin(connect<PresentationOwnProps, PresentationStateProps>(mapDateState)(Presentation), dateMargin),
  isDateVisible,
)
