import classNames from 'classnames'
import React from 'react'
import sc from '../../classes.scss'
import s from './description.scss'
import {DescriptionProps} from './interfaces'

export const Description = ({event, onImage}: DescriptionProps) => {
  return (
    <div
      className={classNames(s.root, sc.textNoMargin, sc.preWrap, {
        [s.onImage]: onImage,
      })}
      data-hook="ev-description"
    >
      {event.description}
    </div>
  )
}
