import {isMobile} from '../../../../../../commons/selectors/environment'
import {getComponentConfig} from '../../../selectors/settings'
import {getImageGridPosition, getImageWidth, getWidgetLayout, isImageVisible} from '../../../selectors/single-settings'
import {AppProps} from '../../app/interfaces'
import {renderIfVisible} from '../../hoc/render-if-visible'
import {connect} from '../../runtime-context/connect'
import {Image as ImagePresentation} from './image'
import {ImageOwnProps, ImageStateProps} from './interfaces'

const mapState = ({state}: AppProps): ImageStateProps => {
  const componentConfig = getComponentConfig(state)

  return {
    layout: getWidgetLayout(componentConfig),
    size: getImageWidth(componentConfig),
    gridPosition: getImageGridPosition(componentConfig),
    isMobile: isMobile(state),
  }
}

export const Image = renderIfVisible(
  connect<ImageOwnProps, ImageStateProps>(mapState)(ImagePresentation),
  isImageVisible,
)
