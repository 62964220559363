import {getAlignmentClassName} from '../../../selectors/classes'
import {getComponentConfig} from '../../../selectors/settings'
import {getAlignment, isOnImageLayout} from '../../../selectors/single-settings'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {Divider as DividerPresentation} from './divider'
import {DividerOwnProps, DividerStateProps} from './interfaces'

const mapState = ({state}: AppProps): DividerStateProps => ({
  alignmentClassName: getAlignmentClassName(getAlignment(getComponentConfig(state))),
  onImage: isOnImageLayout(state),
})

export const Divider = connect<DividerOwnProps, DividerStateProps>(mapState)(DividerPresentation)
