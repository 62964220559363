import React from 'react'
import {Members} from '../../members'
import {SingleEventMembersProps} from './interfaces'
import s from './members.scss'

export const SingleEventMembers = ({event, onImage}: SingleEventMembersProps) => (
  <div className={s.members} data-hook="ev-members">
    <Members event={event} singleLayout={true} onImage={onImage} />
  </div>
)
